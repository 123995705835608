'use client';

import React from 'react';

import { useRouter } from 'next/navigation';

import { OutlineButton, PrimaryButton } from '@components/custom-buttons';
import { paths } from '@routes/path';

const NavButtons = () => {
    const router = useRouter();
    return (
        <>
            <OutlineButton
                type="button"
                buttonLabel="Become a Mentee"
                onClick={() => {
                    router.push(paths.mentee.auth.signup);
                }}
            />
            <PrimaryButton
                type="button"
                buttonLabel="Become a Mentor"
                onClick={() => {
                    router.push(paths.mentor.auth.signup);
                }}
            />
        </>
    );
};

export default NavButtons;
