import React from 'react';

import Link from 'next/link';

import { CustomText } from '@components/Custom-text';
import { useActiveLink } from '@hooks/useActiveLink';

interface NavItemProps {
    title: string;
    path: string;
    isTargetBlank?: boolean;
    onClick?: React.MouseEventHandler<HTMLAnchorElement> | undefined;
}

const NavItem = ({ title, path, isTargetBlank, onClick }: NavItemProps) => {
    const active = useActiveLink(path);
    return (
        <Link onClick={onClick} href={path} target={isTargetBlank ? '_blank' : ''}>
            <CustomText weight={active ? 500 : 400} color={active ? 'primary.light' : 'mainText'}>
                {title}
            </CustomText>
        </Link>
    );
};

export default NavItem;
