export enum ProfileStage {
    ProfileIncomplete = 'PROFILE_INCOMPLETE',
    BasicProfileSetup = 'BASIC_PROFILE_SETUP',
    ProfileCompleted = 'PROFILE_COMPLETED',
}

export enum AccountStatus {
    InActive = 'IN_ACTIVE',
    Active = 'ACTIVE',
    Disabled = 'DISABLED',
    Blocked = 'BLOCKED',
    InReview = 'IN_REVIEW',
}

export enum UserType {
    Mentor = 'MENTOR',
    Mentee = 'MENTEE',
}

export enum TokenType {
    Access = 'ACCESS',
    Refresh = 'REFRESH',
    Verify = 'VERIFY',
}

export enum WeekDay {
    Sun = 'SUN',
    Mon = 'MON',
    Tue = 'TUE',
    Wed = 'WED',
    Thu = 'THU',
    Fri = 'FRI',
    Sat = 'SAT',
}

export enum BlackoutStatus {
    Upcoming = 'UPCOMING',
    Completed = 'COMPLETED',
}

export enum OrderDirection {
    Asc = 'ASC',
    Desc = 'DESC',
}

export enum BlackoutOrderBy {
    Name = 'name',
    Date = 'date',
    CreatedAt = 'createdAt',
}

export enum SessionsTabType {
    UPCOMING = 'UPCOMING',
    PREVIOUS = 'PREVIOUS',
    CANCELLED = 'CANCELLED',
}

export enum StepperStatus {
    COMPLETE = 'COMPLETE',
    IN_PROGRESS = 'IN_PROGRESS',
    PENDING = 'PENDING',
}

export enum NotificationStatus {
    READ = 'READ',
    UNDREAD = 'UNREAD',
}

export enum CalendarType {
    GOOGLE = 'GOOGLE',
    OUTLOOK = 'OUTLOOK',
    YAHOO = 'YAHOO',
}

export enum SessionCalenderView {
    MONTH = 'month',
    YEAR = 'year',
    DECADE = 'decade',
    CENTURY = 'century',
}
