'use client';

import React from 'react';

import { paths } from '@routes/path';

import NavItem from './NavItem';

type NavLinksType = {
    onClose?: () => void;
};

const NavLinks = ({ onClose }: NavLinksType) => (
    <>
        <NavItem onClick={onClose} title="Home" path={paths.general.home} />
        <NavItem onClick={onClose} title="Find a mentor" path={paths.general.findMentor} />
        <NavItem onClick={onClose} title="Contact" path={paths.general.contact} />
        <NavItem onClick={onClose} title="Pricing" path={paths.general.pricing} />
        <NavItem onClick={onClose} title="How it Works" path={paths.general.howItWorks} />
    </>
);

export default NavLinks;
