'use client';

import React from 'react';

import { Box } from '@chakra-ui/react';
import Image from 'next/image';

type SocialPlatformIconProps = {
    src: string;
    alt: string;
    href: string;
};

const SocialPlatformIcon = ({ src, alt, href }: SocialPlatformIconProps) => (
    <Box
        display="flex"
        w="30px"
        h="30px"
        p="7px"
        justify-content="center"
        align-items="center"
        borderRadius="50px"
        bgColor="primary.dark"
    >
        <a href={href} target="_blank" rel="noopener noreferrer">
            <Image src={src} alt={alt} />
        </a>
    </Box>
);

export default SocialPlatformIcon;
