'use client';

import React from 'react';

import { PublicLayout } from '@layouts/public';

interface LayoutProps {
    children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => <PublicLayout>{children}</PublicLayout>;

export default Layout;
